*,
option,
button,
select {
  text-transform: uppercase
}

*::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 3px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.f1 {
  padding: 25px;
  background: #fff;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}

.f1 h3 {
  margin-top: 0;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.f1-steps {
  overflow: hidden;
  position: relative;
  margin-top: 20px;
  text-align: center;
}

.f1-progress {
  position: absolute;
  top: 24px;
  left: 0;
  width: 100%;
  height: 1px;
  background: #ddd;
}

.f1-progress-line {
  position: absolute;
  top: 0;
  left: 0;
  height: 1px;
  background: #28a745;
}

.f1-step {
  position: relative;
  float: left;
  width: 33.333333%;
  padding: 0 5px;
}

.f1-step-icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-top: 4px;
  background: #ddd;
  font-size: 16px;
  color: #fff;
  line-height: 40px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  text-align: center;
}

.f1-step-icon:hover {
  margin-top: 0;
  width: 48px;
  height: 48px;
  font-size: 22px;
  background: #fff;
  color: #28a745;
  border: 1px solid #ddd;
}

.f1-step.activated .f1-step-icon {
  background: #fff;
  border: 1px solid #28a745;
  color: #28a745;
  line-height: 38px;
}

.f1-step.active .f1-step-icon {
  margin-top: 0;
  width: 48px;
  height: 48px;
  margin-top: 0;
  background: #28a745;
  color: #fff;
  font-size: 22px;
  line-height: 48px;
}

.f1-step p {
  color: #ccc;
}

.f1-step.activated p {
  color: #28a745;
}

.f1-step.active p {
  color: #28a745;
}

.f1 fieldset {
  display: none;
  text-align: left;
}

.f1-buttons {
  text-align: right;
}

.f1 .input-error {
  border-color: #28a745;
}

.incoming_msg {
  width: 50%;
}

.incoming_msg_img {
  display: inline-block;
  width: 15%;
}

.incoming_msg_img img {
  width: 100%;
}

.received_msg {
  display: inline-block;
  padding: 0 0 0 10px;
  vertical-align: top;
  width: 85%;
}

.received_withd_msg p {
  background: #ebebeb none repeat scroll 0 0;
  border-radius: 0 15px 15px 15px;
  color: #646464;
  font-size: 20px;
  margin: 0;
  padding: 5px 10px 5px 12px;
  width: 100%;
}

.time_date {
  color: #747474;
  display: block;
  font-size: 12px;
  margin: 8px 0 0;
}

.received_withd_msg {
  width: 57%;
}

.outgoing_msg {
  overflow: hidden;
  margin: 26px 0 26px;
}

.sent_msg {
  float: right;
  width: 46%;
}

.sent_msg p {
  background: #0465ac;
  border-radius: 12px 15px 15px 0;
  font-size: 20px;
  margin: 0;
  color: #fff;
  padding: 5px 10px 5px 12px;
  width: 100%;
}

.user_history {
  border-right: 2px #ddd solid;
  width: 25%;
}

.user_cation {
  border-bottom: 2px #ddd solid;
}

.msg_history {
  overflow-y: auto;
  width: 75%;
}

.toolbody {
  border: 1px solid black !important;
}

.toolbody.place-top::after {
  border-left: 10px solid transparent !important;
  border-right: 10px solid transparent !important;
  border-top: 6px black solid !important;
  /* border: 8px solid black !important */
}

.rbc-row-content {
  visibility: hidden;
}

.modal-warning .modal-header {
  background-color: #f6c23e !important;
  color: #fff !important;
  border-color: #c87f0a;
}